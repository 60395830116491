import Select from "react-select";
import React, { useState } from "react";
import * as _ from "lodash";

export default function ReactTableFilterSelectComponent(props) {
  const [value, setValue] = useState([]);

  const filterList = _.uniqBy(props.options, props.optionValue).map((item) => {
    return {
      value: _.get(item, props.optionValue, "err"),
      label: _.get(item, props.optionLabel, "err"),
    };
  });

  return (
    <>
      <div style={{ position: "relative" }}>
        <Select
          className="react-select primary"
          classNamePrefix="react-select"
          menuPortalTarget={document.body}
          menuPosition="fixed"
          styles={{
            menuPortal: (base) => ({
              ...base,
              zIndex: 999999,
            }),
            // control: (base) => ({
            //   ...base,
            //   backgroundColor: "#27293d",
            // }),
            // menu: (base) => ({
            //   ...base,
            //   backgroundColor: "#27293d",
            // }),
            // option: (base) => ({
            //   ...base,
            //   backgroundColor: "#27293d",
            //   "&:hover": {
            //     backgroundColor: "#1d1e26",
            //   },
            // }),
          }}
          value={props.value ? props.value : value}
          multiple={true}
          isMulti
          getOptionLabel={(item) => {
            if (props.getOptionLabel) return props.getOptionLabel(item);
            return item.label;
          }}
          getOptionValue={(item) => item.value}
          onChange={(value) => {
            props.onChange(value);
            if (props.handleValue) {
              setValue(value);
            }
          }}
          options={filterList}
          placeholder="---"
        />
      </div>
    </>
  );
}
